:root {
  /* --mainColor: #E5E7D9; */
  --mainColor: #8a9450;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body {
  background-color: var(--mainColor);
  font-family:  Montserrat,sans-serif,Arial;
  }
  #aboutUsbackgroundImage{
    background:  url(../../images/aboutUs.jpg) no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-attachment: fixed;
    color:white;
    box-shadow: inset 0 0 0 50000px rgba(0,0,0,.3);
  } 
  .main-content {
    background-image: url('../../images/ocm_owl_large.jpg');
    background-size:     cover;
    background-repeat:   no-repeat;
    background-position: center center;
    background-attachment: fixed;
    }
.text-document{
  background-color: white;
  opacity: 0.8;
  min-height: 500px;
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.text-document h2{
  color: var(--fontColor);
  font-size: 22px;
  margin: 15px 0px;
  text-transform: capitalize;
  }
  .text-document h3{
    color: #6d7a2b;
    font-size: 20px;
    margin: 15px 0px;
    }
  a:hover{
    color: #6d7a2b;
    }
    .text-document a{
      color: black;
      }
      .sidenav {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 2;
        top: 0;
        left: 0;
        background-color: var(--mainColor);
        overflow-x: hidden;
        transition: 0.5s;
        padding-top: 60px;
      }

      .sidenav a {
        padding: 8px 8px 8px 32px;
        text-decoration: none;
        font-size: 20px;
        color: var(--fontColor);
        display: block;
        transition: 0.3s;
      }

      .sidenav a:hover {
        color: #f1f1f1;
      }

      .sidenav .item {
        cursor: pointer;
      }
      .sidenav .closebtn {
        position: absolute;
        top: 0;
        right: 25px;
        font-size: 36px;
        margin-left: 50px;
        cursor: pointer;
      }

      @media screen and (max-height: 450px) {
        .sidenav {padding-top: 15px;}
        .sidenav a {font-size: 18px;}
      }
      .img-wrapper{
        overflow:hidden;
      }
      img.hover-zoom:hover {
        transform: scale(1.25);
      }
      /* Main Footer */
footer .main-footer{  padding: 20px 0 0 0;  background: inherit; color:white;}
footer ul{  padding-left: 0;  list-style: none;}

/* Copy Right Footer */
.footer-copyright p { margin: 0 0 5px 0; font-size: smaller; color:white }

/*==================== 
  Widgets 
====================== */
.widget li a{ font-size: 10px; color:white}
.widget li a:hover{ color: var(--mainColor);}
